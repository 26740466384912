<template>
    <div>
      <b-modal
        id="modalDelete"
        :title="'Seguro que quieres eliminar la Factura N° '+detalle.nro_factura+''"
        size="md"
        hide-footer
        >        
            <div class="row pb-3 px-3">
                <div class="col-sm-12 text-center">
                    <div class="row form-group pl-3">
                        <label for="" class="col-form-label">Motivo Anulacion:</label>
                        <div class="col">
                            <multiselect
                                id="sucursal"
                                track-by="descripcion"
                                label="descripcion"
                                placeholder="Seleccione un Motivo"
                                v-model="motivo"
                                :show-labels="false"
                                :options="motivoAnulacion"
                                :searchable="true"
                                >
                            </multiselect>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 text-center">
                    <button class="btn btn-block th-custom-color" @click="[setDelete(detalle.id), closeModal()]">
                        Eliminar Factura
                    </button>
                </div>
                <div class="col-sm-6 text-center">
                    <button class="btn btn-block btn-secondary" @click="closeModal">
                        Cancelar
                    </button>
                </div>
            </div> 
      </b-modal>      
    </div>
  </template>
<script>

import { call, sync } from 'vuex-pathify';
import Multiselect from 'vue-multiselect';

export default {
    name: 'modalPDF',
    props: {      
        detalle: {
            type: Object,
            default: null,
        },
    },
    components: {
        Multiselect,
    },
    data: () => ({      
    }),
    methods: {
        closeModal() {
            this.$bvModal.hide('modalDelete');
        },
        ...call('facturacion/facturacionIndex', [
            'setDelete'
        ])    
    },
    watch: {},
    computed: {
        ...sync('facturacion/facturacionIndex', [
            'motivo',
            'motivoAnulacion'
        ])
    },
};
</script>
  
  