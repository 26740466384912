<template>
    <div>
      <b-modal
        id="modalPdf"
        :title="'Factura N° '+detalle.nro_factura+''"
        size="sm"
        hide-footer
        >        
            <div class="row pb-3 px-3">
                <div class="col-sm-12 text-center">
                    <button class="btn btn-block th-custom-color" @click="getPdfNormal(detalle.id)">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-printer"
                            viewBox="0 0 16 16"
                        >
                            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                            <path
                            d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2
                            2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2
                            2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2
                            2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2
                            2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1
                            1 0 0 1 1 1z"
                            />
                        </svg>
                        Imprimir Factura
                    </button>
                    <button class="btn btn-block btn-secondary" @click="getPdfRapida(detalle.id)">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-printer"
                            viewBox="0 0 16 16"
                        >
                            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                            <path
                            d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2
                            2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2
                            2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2
                            2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2
                            2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1
                            1 0 0 1 1 1z"
                            />
                        </svg>
                        Impresion Rapida 
                    </button>
                </div>
            </div> 
      </b-modal>
      <iframe
        name="printer"
        id="printer"
        :src="downloadUrl"
        type="application/pdf"
        width="100%"
        height="770vh"
        style="display: none;"
      >
      </iframe>
    </div>
  </template>
  <script>

import { call, sync } from 'vuex-pathify';
export default {
    name: 'modalPDF',
    props: {      
        detalle: {
            type: Object,
            default: null,
        },
    },
    data: () => ({      
    }),
    methods: {
        closeModal() {
            this.$bvModal.hide('modalPdf');
        },
        ...call('facturacion/facturaccionPdf', [
            'getPdfNormal',
            'getPdfRapida'
        ])    
    },
    watch: {},
    computed: {
        ...sync('facturacion/facturaccionPdf', [
            'downloadUrl'
        ])
    },
};
</script>
  
  