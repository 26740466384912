<template>
    <div>
        <div class="breadcrumb">
            <h1>Registro de Facturacion</h1>
        </div>
        <div class="row mb-4">
            <div class="col-sm-12 mb-4">
                <div class="card text-left">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-sm-3">
                                <!-- <router-link :to="{ name: 'facturacion-create' }">
                                    <button class="btn th-custom-color">Nuevo</button>
                                </router-link> -->
                            </div>
                            <div class="col-sm-3 offset-sm-6">
                                <div class="text-right">
                                    <label class="switch switch-success mr-3">
                                        <input id="filterbyState"
                                        type="checkbox"
                                        :checked="estado"
                                        @change="listarFacturacion"
                                        v-model="estado"
                                        >
                                        <span class="slider"></span>
                                        <span>{{ labelActivos }}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row mb-1">
                        <div class="col-sm-3">
                            <div class="btn-group">
                                <span class="pt-2 mr-1">Mostrar </span>
                                <b-form-select
                                    v-model="porPagina"
                                    :options="paginaOpciones"
                                    @change="listarFacturacion">
                                </b-form-select>
                                <span class="pt-2 ml-1"> Registros</span>
                            </div>
                        </div>
                        <div class="col-sm-3 offset-sm-6 text-right">
                            <div class="form-group row">
                                <div class="col-sm-4"></div>
                                <div class="col-sm-8">
                                    <input type="text"
                                    class="form-control"
                                    v-model="filter"
                                    @keyup="filtrarBusqueda"
                                    autocomplete="off"
                                    placeholder="Buscar"
                                    >
                                </div>
                            </div>
                        </div>
                        <div v-if="loading" style="display: block; width: 100%">
                            <div class="text-center text-danger screen-center" style="padding-top: 10%;">
                                <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
                                <strong>Cargando...</strong>
                            </div>
                        </div>
                        <div v-else style="display: block; width: 100%">
                            <b-table
                                class="table table-striped table-bordered table-hover"
                                :fields="fields"
                                :items="tablaFacturacions"
                            >
                                <template #cell(editar)="items" v-if="estado">
                                    <router-link
                                    :to="{ name: 'facturacion-edit', params: {id: encrypt(items.item.id)}}"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Editar">
                                        <i class="nav-icon i-Pen-5 text-success font-weight-bold"
                                        title="editar" style="cursor: pointer;"></i>
                                    </router-link>
                                </template>
                                <template #cell(importe)="data">
                                    {{ data.item.importe | numeral('0,0.00') }}
                                </template>
                                <template #cell(tipo_emision)="data">
                                    <span class="font-weight-bold" :class="data.item.verificacion == 1 ? 'text-success' : 'text-danger'">{{ data.item.tipo_emision }}</span>
                                </template>
                                <template #cell(ver)>
                                    <i class="nav-icon i-Eye font-weight-bold text-warning"
                                    title="ver venta"
                                    style="cursor: pointer;"></i>
                                </template>
                                <template #cell(pdf)="data">
                                    <i class="nav-icon i-File-Download font-weight-bold text-info"
                                    style="cursor: pointer;"
                                    title="pdf" @click="[detalle=data.item, openModalPdf()]"></i>
                                </template>
                                <template #cell(eliminar)="data" v-if="estado">
                                    <i v-if="data.item.verificacion == 1" class="nav-icon i-Close-Window font-weight-bold text-danger"
                                        style="cursor: pointer" title="Eliminar" @click="[detalle=data.item, openModalDelete()]"
                                    ></i>
                                    <i v-else class="nav-icon i-Close-Window font-weight-bold text-secondary"
                                        style="cursor: pointer" title="Sin Verificar"
                                    ></i>
                                </template>
                            </b-table>
                            <div class="row">
                            <div class="col-sm-8"></div>
                            <div class="col-sm-4">
                                <b-pagination
                                v-model="page"
                                :total-rows="total"
                                :per-page="porPagina"
                                @input="listarFacturacion"
                                class="my-0 float-right"
                                ></b-pagination>
                            </div>
                            </div>
                        </div>                        
                    </div>
                    <pdf :detalle="detalle" />
                    <deleteVenta :detalle="detalle" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { get, sync, call } from 'vuex-pathify';
import pdf from '../components/TheModalPdf.vue';
import deleteVenta from '../components/TheModalDelete.vue';
export default {
    name: "index",
    components: {
        pdf,
        deleteVenta
    },
    data() {
        return {
            detalle: {}
        };
    },
    created() {
        this.getIndex();
        this.getCreate();
    },
    methods: {
        listarFacturacion() {
            this.getIndex()
        },
        filtrarBusqueda() {
            const DELAY = 400;
            clearTimeout(this.delayTimeout);
            this.delayTimeout = setTimeout(this.listarFacturacion, DELAY);
        },
        encrypt(id) {
            return btoa(id);
        },
        ...call("facturacion/facturacionIndex", [
            'getIndex',
            'getCreate'
        ]),
        openModalPdf() {
            this.$bvModal.show('modalPdf');
        },
        openModalDelete() {
            this.$bvModal.show('modalDelete');
        }
    },
    computed: {
        labelActivos() {
        if (this.estado) {
            return 'Activos';
        }
        return 'Inactivos';
        },
        ...get("facturacion/facturacionIndex", [
            'fields',
            'tablaFacturacions',
        ]),
        ...sync("facturacion/facturacionIndex", [
            'loading',
            'estado',
            'porPagina',
            'paginaOpciones',
            'filter',
            'delayTimeout',
            'total',
            'page'
        ]),
    },
    watch: {},

}
</script>